.FrontCategory {
  width: auto;
  height: 280px;
  border-top: 0px;
  border-right: 1px solid rgb(229, 229, 229);
  border-bottom: 1px solid rgb(229, 229, 229);
  border-collapse: collapse;
  border-bottom-width: 100%;
  padding: 10px;
  color:black;
  text-decoration: none;
}

.FrontCategory:hover {
  background-color: #e5e5e5;
  text-decoration: none;
}

.name {
  text-decoration: none;
}

.BTN {
  float: right;
}

.nanarBtn {
  border: none;
  color: white;
  padding: 12px 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background-color: #00afa5;
}

.FrontCarPic {
  width: auto;
  height: 50vw;
  max-height: 170px;
  display: flex;
  justify-content: center;
}

img {
  max-width: 100%;
  height: auto;
  max-height: 160px;
  object-fit: cover;
}