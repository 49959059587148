.BookingBar {
    height: 375px;
}

.Error {
    font-size: 20px;
    color:red;
}

.NoMatch {
    text-align: center;
}

.infoSiteMain {
    padding: 25px;
}
