
.NavHead {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #101820;
  }

ul.NavHead {
  display: flex;
  flex-direction: row;
}

li.NavHead {
  flex: 1 0 auto;
  text-align: center;
  border: .5px solid white;
  }
  
.NavHead a {
    min-height: 50px;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
.NavHead a:hover {
    background-color: #00afa5;
    color: white;
  }


.DropDown {
  padding: 1px;
  margin: 1px;
  display: none;
  width: 100%;
  height: 53px;
  background: rgb(0, 94, 184);
  border: solid 3px rgb(0, 94, 184);
  color: white;
}

.Header {
  padding-top: 15px;
  padding-bottom: 15px;
  margin:15px;
}

.headPhone {
  float: right;
  margin-bottom: 5px;
  margin-right: 5px;
}

.headOpen {
  margin-bottom: 5px;
  margin-right: 5px;
}

.facebook {
  color:#00afa5;
  text-decoration: none;
}

.questionmark {
  color:#00afa5;
  text-decoration: none;
}

.font {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  color:#00afa5;
  text-decoration: none;
}

.uppl {
  color:#00afa5;
  font-size: 18px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.infoText {
  margin: 5px;
}

@media only screen and (max-width: 769px) {
  ul.NavHead {
    flex-direction: column;
    display: none;
  }
    
  .DropDown {
    display: block;
  }
    
  .DropDown + ul.NavHead {
    display: flex;
  }

}

@media only screen and (max-width: 766px) {
  .infoText {
    display: none;
  }
}
  