body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Artegra Sans Alt";
  src: local('Artegra Sans Alt'), url("/fonts/._Artegra - Artegra Sans Alt Regular.otf") format("otf");
  }
.Page {
  max-width: 1230px;
  margin: 0 auto;
  background-color: white;
  /* font-family: "Artegra Sans Alt"; */
}


.NavHead {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #101820;
  }

ul.NavHead {
  display: flex;
  flex-direction: row;
}

li.NavHead {
  flex: 1 0 auto;
  text-align: center;
  border: .5px solid white;
  }
  
.NavHead a {
    min-height: 50px;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
.NavHead a:hover {
    background-color: #00afa5;
    color: white;
  }


.DropDown {
  padding: 1px;
  margin: 1px;
  display: none;
  width: 100%;
  height: 53px;
  background: rgb(0, 94, 184);
  border: solid 3px rgb(0, 94, 184);
  color: white;
}

.Header {
  padding-top: 15px;
  padding-bottom: 15px;
  margin:15px;
}

.headPhone {
  float: right;
  margin-bottom: 5px;
  margin-right: 5px;
}

.headOpen {
  margin-bottom: 5px;
  margin-right: 5px;
}

.facebook {
  color:#00afa5;
  text-decoration: none;
}

.questionmark {
  color:#00afa5;
  text-decoration: none;
}

.font {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  color:#00afa5;
  text-decoration: none;
}

.uppl {
  color:#00afa5;
  font-size: 18px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.infoText {
  margin: 5px;
}

@media only screen and (max-width: 769px) {
  ul.NavHead {
    flex-direction: column;
    display: none;
  }
    
  .DropDown {
    display: block;
  }
    
  .DropDown + ul.NavHead {
    display: flex;
  }

}

@media only screen and (max-width: 766px) {
  .infoText {
    display: none;
  }
}
  
.BookingBar {
    height: 375px;
}

.Error {
    font-size: 20px;
    color:red;
}

.NoMatch {
    text-align: center;
}

.infoSiteMain {
    padding: 25px;
}

.Backend {
    margin: 0 auto;
    margin-bottom: 20px;
}

.Title {
    text-align: center;
}

.Buttons {
    width: 800px;
    margin: 25px;
}

.NextPage {
    margin-left: 10px;
}
.PrevPage {
    margin-right: 10px;
}

.Button {
    background-color: rgb(0, 94, 184);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 5px;
    cursor: pointer;
    min-width: 250px;
    width: 100%;
    height: 100px;
}

.List {
    padding-bottom: 50px;
    margin: 0 auto;
}

.ListItem {
    list-style-type: none;
    height: 40px;
    border: 1px solid black;
    margin-bottom:5px;
    margin-top: 5px;
    background-color: lightgray;
}

a {
    text-decoration: none;
    color: black;
}

.ListItem:hover {
    background-color: white;
    color: rgb(0, 0, 0);
    cursor: pointer;
}

.Deletebtn {
    background-color: rgb(0, 94, 184);
    border: none;
    color: white;
    margin-top: 5px;
    width: 40px;
    height: 40px; 
    font-size: 16px;
}

.Deletebtn:hover {
    background-color: rgb(108, 171, 243);
    color: rgb(0, 0, 0);
    cursor: pointer;
}

.BaetaVid {
    border: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.87);
}

._textBox {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    margin-top: 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.submitButton {
    border: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.87);
}

.DropZone {
    border:solid black 1px;
    min-height: 160px;
    text-align: center;
}


.btn-primary {
    background-color: #00afa5;
    border-color: #00afa5;
}

.btn-primary:hover {
    background-color: #00afa5;
    border-color: #00afa5;
}
.FrontBorder {
    height: 50vw;
    max-height: 600px;
}

.Text {
    float: left;
    width: 55%;

    position: relative;
    min-height: 1px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 115px;
    background: rgba(50,50,50,.6);
    padding-top: 20px;
    color: #fff;
    text-shadow: 0 2px 0 rgba(0,0,0,.61);
    font-size: 18px;
}

.divBanner {
    color: white;
    background-color: #00afa5;
    justify-content: center;
}

.pBanner {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 1rem;
}

.BookNowButton img {
    height: 20vw;
}

.BookNowButton {
    position: absolute;
    right: 0;
    bottom: 10px;
}
.BookNowButton:hover {
    cursor: pointer;
}

.FrontCategory {
  width: auto;
  height: 280px;
  border-top: 0px;
  border-right: 1px solid rgb(229, 229, 229);
  border-bottom: 1px solid rgb(229, 229, 229);
  border-collapse: collapse;
  border-bottom-width: 100%;
  padding: 10px;
  color:black;
  text-decoration: none;
}

.FrontCategory:hover {
  background-color: #e5e5e5;
  text-decoration: none;
}

.name {
  text-decoration: none;
}

.BTN {
  float: right;
}

.nanarBtn {
  border: none;
  color: white;
  padding: 12px 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background-color: #00afa5;
}

.FrontCarPic {
  width: auto;
  height: 50vw;
  max-height: 170px;
  display: flex;
  justify-content: center;
}

img {
  max-width: 100%;
  height: auto;
  max-height: 160px;
  object-fit: cover;
}
.Car {
  min-height: 540px;
  margin: auto 0;
  border: 1px solid rgb(204,204,204);
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 5px;
}

td:nth-child(odd) {
  font-weight: bold;
}
td:nth-child(even) {
  text-align: right

}
tr, td{
  padding: 2px;
  border-bottom: 1px solid #ddd; 
}

._btn {
  border: none;
  color: white;
  padding: 12px 5px;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.87);
}

.Category {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.timeSelect {
    padding: 5px;
    margin: 20px;
    min-height: 200px;
}

.timeButton {
    border: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: green;
}

.timeButton:disabled {
    background-color: red;
}

.submitButton {
    background-color: #00afa5;
}

.priceInfo {
    margin: 10px;
    padding: 10px;
    border: solid rgb(74, 125, 176) 2px;
    background-color: rgb(242, 249, 254);
}

.cardForm {
    min-height: 85px;
    margin: 10px;
    padding: 10px;
}

.PlaceTimeInfo {
    margin: 10px;
    padding: 10px;
    border: solid #00afa5;
}

.CarInfo {
    margin: 10px;
    padding: 10px;
    border: solid #00afa5;
}

#hiddenfields {
    height: 0px;
    visibility: hidden;
}

.mainlabel {
    margin-top: 0.5rem;
    font-size: 22px;
}

.infotext {
    margin-top: 2.5rem;
    font-size: 18px;
}

a {
    color: #00afa5;
}
ul.NavFoot {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #101820;
  }

  li.NavFoot {
    flex: 1 0 auto;
    text-align: center;
  }
  
  li.NavFoot a {
    padding: 1rem;
    min-height: 50px;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
  li.NavFoot a:hover {
    background-color: #00afa5;
    color: white;
  }

.footer {
    margin-top: 1rem;
    text-align: center;
    a:link {
      color: #00afa5;
    }
}

@media screen and (max-width: 768px) {
  ul.NavFoot {
    flex-direction: column;
  }
}
  
