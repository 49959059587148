@font-face {
  font-family: "Artegra Sans Alt";
  src: local('Artegra Sans Alt'), url("/fonts/._Artegra - Artegra Sans Alt Regular.otf") format("otf");
  }
.Page {
  max-width: 1230px;
  margin: 0 auto;
  background-color: white;
  /* font-family: "Artegra Sans Alt"; */
}
