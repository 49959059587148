.Car {
  min-height: 540px;
  margin: auto 0;
  border: 1px solid rgb(204,204,204);
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 5px;
}

td:nth-child(odd) {
  font-weight: bold;
}
td:nth-child(even) {
  text-align: right

}
tr, td{
  padding: 2px;
  border-bottom: 1px solid #ddd; 
}

._btn {
  border: none;
  color: white;
  padding: 12px 5px;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.87);
}

.Category {
  padding-top: 1rem;
  padding-bottom: 1rem;
}