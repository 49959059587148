.Backend {
    margin: 0 auto;
    margin-bottom: 20px;
}

.Title {
    text-align: center;
}

.Buttons {
    width: 800px;
    margin: 25px;
}

.NextPage {
    margin-left: 10px;
}
.PrevPage {
    margin-right: 10px;
}

.Button {
    background-color: rgb(0, 94, 184);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 5px;
    cursor: pointer;
    min-width: 250px;
    width: 100%;
    height: 100px;
}

.List {
    padding-bottom: 50px;
    margin: 0 auto;
}

.ListItem {
    list-style-type: none;
    height: 40px;
    border: 1px solid black;
    margin-bottom:5px;
    margin-top: 5px;
    background-color: lightgray;
}

a {
    text-decoration: none;
    color: black;
}

.ListItem:hover {
    background-color: white;
    color: rgb(0, 0, 0);
    cursor: pointer;
}

.Deletebtn {
    background-color: rgb(0, 94, 184);
    border: none;
    color: white;
    margin-top: 5px;
    width: 40px;
    height: 40px; 
    font-size: 16px;
}

.Deletebtn:hover {
    background-color: rgb(108, 171, 243);
    color: rgb(0, 0, 0);
    cursor: pointer;
}

.BaetaVid {
    border: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.87);
}

._textBox {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    margin-top: 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.submitButton {
    border: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.87);
}

.DropZone {
    border:solid black 1px;
    min-height: 160px;
    text-align: center;
}

