.FrontBorder {
    height: 50vw;
    max-height: 600px;
}

.Text {
    float: left;
    width: 55%;

    position: relative;
    min-height: 1px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 115px;
    background: rgba(50,50,50,.6);
    padding-top: 20px;
    color: #fff;
    text-shadow: 0 2px 0 rgba(0,0,0,.61);
    font-size: 18px;
}

.divBanner {
    color: white;
    background-color: #00afa5;
    justify-content: center;
}

.pBanner {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-top: 1rem;
}

.BookNowButton img {
    height: 20vw;
}

.BookNowButton {
    position: absolute;
    right: 0;
    bottom: 10px;
}
.BookNowButton:hover {
    cursor: pointer;
}
