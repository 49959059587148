.timeSelect {
    padding: 5px;
    margin: 20px;
    min-height: 200px;
}

.timeButton {
    border: none;
    color: white;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    background-color: green;
}

.timeButton:disabled {
    background-color: red;
}

.submitButton {
    background-color: #00afa5;
}

.priceInfo {
    margin: 10px;
    padding: 10px;
    border: solid rgb(74, 125, 176) 2px;
    background-color: rgb(242, 249, 254);
}

.cardForm {
    min-height: 85px;
    margin: 10px;
    padding: 10px;
}

.PlaceTimeInfo {
    margin: 10px;
    padding: 10px;
    border: solid #00afa5;
}

.CarInfo {
    margin: 10px;
    padding: 10px;
    border: solid #00afa5;
}

#hiddenfields {
    height: 0px;
    visibility: hidden;
}

.mainlabel {
    margin-top: 0.5rem;
    font-size: 22px;
}

.infotext {
    margin-top: 2.5rem;
    font-size: 18px;
}

a {
    color: #00afa5;
}