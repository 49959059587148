ul.NavFoot {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #101820;
  }

  li.NavFoot {
    flex: 1 0 auto;
    text-align: center;
  }
  
  li.NavFoot a {
    padding: 1rem;
    min-height: 50px;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  
  /* Change the link color to #111 (black) on hover */
  li.NavFoot a:hover {
    background-color: #00afa5;
    color: white;
  }

.footer {
    margin-top: 1rem;
    text-align: center;
    a:link {
      color: #00afa5;
    }
}

@media screen and (max-width: 768px) {
  ul.NavFoot {
    flex-direction: column;
  }
}
  